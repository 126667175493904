exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alternatives-js": () => import("./../../../src/pages/alternatives.js" /* webpackChunkName: "component---src-pages-alternatives-js" */),
  "component---src-pages-austria-js": () => import("./../../../src/pages/austria.js" /* webpackChunkName: "component---src-pages-austria-js" */),
  "component---src-pages-denmark-js": () => import("./../../../src/pages/denmark.js" /* webpackChunkName: "component---src-pages-denmark-js" */),
  "component---src-pages-france-js": () => import("./../../../src/pages/france.js" /* webpackChunkName: "component---src-pages-france-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-italy-js": () => import("./../../../src/pages/italy.js" /* webpackChunkName: "component---src-pages-italy-js" */),
  "component---src-pages-netherlands-js": () => import("./../../../src/pages/netherlands.js" /* webpackChunkName: "component---src-pages-netherlands-js" */)
}

